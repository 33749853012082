import { GuideType, InteractiveComponents } from '../../../core/enums';
import { Locale, LocaleKeys } from '../../../core/locale';
import { ScreenTaskControllerTypes } from '../../../core/navigation/ScreenTaskControllerTypes';
import {
    channelOptionsOverlayApplyFilter,
    GLOBAL_MOUSE_TRAP,
    showGuideOptionsOverlay,
} from '../../overlay/channeloptionsoverlay/channeloptionsoverlay.reducers';
import {
    onKeyOkExitConfirmationOverlay,
    showExitConfirmationOverlayDialog,
} from '../../overlay/exitconfirmationoverlay/exitconfirmationoverlay.reducers';
import {
    hideOverlayDialog,
    onKeyDownOverlayDialog,
    onKeyLeftOverlayDialog,
    onKeyRightOverlayDialog,
    onKeyUpOverlayDialog,
    onMouseOutOverlayDialogOperations,
    onMouseOverOverlayDialogOperations,
} from '../../overlaydialog/overlaydialog.reducers';
import { showEmptyGuideScreen, showGuideScreen } from '../../screens/screens.reducers';
import { hideOneLineGuide } from '../../videoplayerui/onelineguide/onelineguide.reducers';

export const onKeyOkEmptyGuide =
    (guideType: GuideType = GuideType.GRID_GUIDE) =>
    (dispatch: Function, getState: Function) => {
        const { verticalPosition, horizontalPosition } = getState().overlayHighlight;
        const { horizontalSelectionOptions } = getState().channelOptionsOverlay;
        const { screenTaskController } = getState().screens;

        const isFavoritesFilterAlreadySelected =
            horizontalSelectionOptions[horizontalPosition[0]] ===
            Locale.getString(LocaleKeys.GUIDE_CHANNEL_TYPES_FAVORITES);

        switch (screenTaskController) {
            case ScreenTaskControllerTypes.EXIT_CONFIRMATION_OVERLAY:
                dispatch(onKeyOkExitConfirmationOverlay());
                break;
            case ScreenTaskControllerTypes.DEFAULT_GUIDE_TASKS:
                break;
            case ScreenTaskControllerTypes.NO_NETWORK_OVERLAY:
                dispatch(hideOverlayDialog());
                break;
            case ScreenTaskControllerTypes.CHANNEL_OPTIONS_OVERLAY:
                if (guideType === GuideType.ONE_LINE_GUIDE && verticalPosition === 0) {
                    dispatch(hideOneLineGuide());
                    dispatch(hideOverlayDialog());
                    dispatch(showEmptyGuideScreen());
                    return;
                }

                if (guideType === GuideType.GRID_GUIDE && isFavoritesFilterAlreadySelected) {
                    dispatch(hideOverlayDialog());
                    return;
                }

                dispatch(channelOptionsOverlayApplyFilter());
                if (guideType === GuideType.GRID_GUIDE) {
                    dispatch(showGuideScreen());
                }

                break;
            default:
                break;
        }
    };

export const onLongKeyOkEmptyGuide =
    (guideType: GuideType = GuideType.GRID_GUIDE) =>
    (dispatch: Function) => {
        dispatch(showGuideOptionsOverlay(guideType));
    };

export const onKeyUpEmptyGuide = () => (dispatch: Function, getState: Function) => {
    const { screenTaskController } = getState().screens;

    switch (screenTaskController) {
        case ScreenTaskControllerTypes.CHANNEL_OPTIONS_OVERLAY:
        case ScreenTaskControllerTypes.EXIT_CONFIRMATION_OVERLAY:
            dispatch(onKeyUpOverlayDialog());
            break;
        default:
            break;
    }
};

export const onKeyDownEmptyGuide = () => (dispatch: Function, getState: Function) => {
    const { screenTaskController } = getState().screens;

    switch (screenTaskController) {
        case ScreenTaskControllerTypes.CHANNEL_OPTIONS_OVERLAY:
        case ScreenTaskControllerTypes.EXIT_CONFIRMATION_OVERLAY:
            dispatch(onKeyDownOverlayDialog());
            break;
        default:
            break;
    }
};

export const onKeyRightEmptyGuide = () => (dispatch: Function, getState: Function) => {
    const { screenTaskController } = getState().screens;
    if (screenTaskController !== ScreenTaskControllerTypes.CHANNEL_OPTIONS_OVERLAY) {
        return;
    }
    dispatch(onKeyRightOverlayDialog());
};

export const onKeyLeftEmptyGuide = () => (dispatch: Function, getState: Function) => {
    const { screenTaskController } = getState().screens;
    if (screenTaskController !== ScreenTaskControllerTypes.CHANNEL_OPTIONS_OVERLAY) {
        return;
    }
    dispatch(onKeyLeftOverlayDialog());
};

export const onKeyBackEmptyGuide = () => (dispatch: Function, getState: Function) => {
    const { screenTaskController } = getState().screens;

    switch (screenTaskController) {
        case ScreenTaskControllerTypes.DEFAULT_GUIDE_TASKS:
            dispatch(showExitConfirmationOverlayDialog());
            return;

        default:
            dispatch(hideOverlayDialog());
    }
};

export const onMouseOverEmptyGuide = () => (dispatch: Function, getState: Function) => {
    const { screenTaskController } = getState().screens;
    if (screenTaskController !== ScreenTaskControllerTypes.CHANNEL_OPTIONS_OVERLAY) {
        return;
    }
    dispatch(onMouseOverOverlayDialogOperations());
};

export const onMouseOutEmptyGuide = () => (dispatch: Function, getState: Function) => {
    const { screenTaskController } = getState().screens;
    if (screenTaskController !== ScreenTaskControllerTypes.CHANNEL_OPTIONS_OVERLAY) {
        return;
    }
    dispatch(onMouseOutOverlayDialogOperations());
};

export const onMouseClickEmptyGuide =
    (guideType = GuideType.GRID_GUIDE) =>
    (dispatch: Function, getState: Function) => {
        const {
            interactiveLayer: { activeComponent },
            inputFactory: { value },
            screens: { screenTaskController },
        } = getState();

        if (screenTaskController !== ScreenTaskControllerTypes.CHANNEL_OPTIONS_OVERLAY) {
            return;
        }

        if (value === GLOBAL_MOUSE_TRAP) {
            dispatch(hideOverlayDialog());
            return;
        }

        if (activeComponent === InteractiveComponents.WEST) dispatch(onKeyLeftOverlayDialog());
        else if (activeComponent === InteractiveComponents.EAST)
            dispatch(onKeyRightOverlayDialog());
        else dispatch(onKeyOkEmptyGuide(guideType));
    };
