import { useEffect, useRef, useState } from 'react';

import { CursorManager } from '../../../../core/navigation';
import { RootState, useTivoSelector } from '../../../../core/tivo/tivo-redux-wrapper';

export const useCursorOptions = () => {
    const activeComponent = useTivoSelector((state: RootState) => state.inputFactory.value);
    const areChannelsSelected = useTivoSelector(
        (state: RootState) => state.oneLineGuide.areChannelsSelected,
    );
    const [contentSelectedBeforeCursor, setContentSelectedBeforeCursor] = useState(
        CursorManager.isCursorAppeared() && !areChannelsSelected,
    );
    const areChannelsSelectedRef = useRef(contentSelectedBeforeCursor);
    const prevCursorState = useRef(CursorManager.isCursorAppeared());

    useEffect(() => {
        areChannelsSelectedRef.current = areChannelsSelected;
    }, [areChannelsSelected]);

    useEffect(() => {
        const id = CursorManager.subscribe((state: boolean) => {
            if (prevCursorState.current === state) {
                return;
            }

            if (state) {
                setContentSelectedBeforeCursor(!areChannelsSelectedRef.current);
            } else {
                setContentSelectedBeforeCursor(false);
            }
            prevCursorState.current = state;
        });
        return () => {
            CursorManager.unsubscribe(id);
        };
    }, []);

    let hideMessage = false;
    if (
        CursorManager.isCursorAppeared() &&
        activeComponent !== 'olg-selected-cell' &&
        activeComponent !== 'olg-selected-channel'
    ) {
        hideMessage = true;
    }

    const hideDescription =
        !contentSelectedBeforeCursor &&
        CursorManager.isCursorAppeared() &&
        activeComponent !== 'olg-selected-cell';

    return {
        activeComponent,
        hideMessage,
        hideDescription,
    };
};
